import React from 'react';

export interface SectionProps {}

const Section: React.SFC<SectionProps> = (props) => {
  return (
    <section className="section">
      <div className="container narrow-container content">{props.children}</div>
    </section>
  );
};

export default Section;

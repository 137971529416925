import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import Content, { HTMLContent } from '../components/Content';
import Section from '../components/Section';

export interface MainPageProps {
  title: string;
  content: string;
  contentComponent?: Function;
}

export const MainPageTemplate: React.SFC<MainPageProps> = ({
  title,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <>
      <div className="hero">
        <div className="hero-body">
          <h1 className="title has-text-centered">{title}</h1>
        </div>
      </div>
      <Section>
        <PageContent className="content" content={content} />
      </Section>
    </>
  );
};

const MainPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <MainPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

export const mainPageQuery = graphql`
  query mainPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default MainPage;
